var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_vm.hasOzon && _vm.hasWb ? _c('app-select', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_marketplace_items,
            "fullwidth": ""
          },
          model: {
            value: _vm.marketplace,
            callback: function ($$v) {
              _vm.marketplace = $$v;
            },
            expression: "marketplace"
          }
        }) : _vm._e(), _c('app-select-multi', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_tokens_items
          },
          model: {
            value: _vm.selectedTokens,
            callback: function ($$v) {
              _vm.selectedTokens = $$v;
            },
            expression: "selectedTokens"
          }
        }), _c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.$store.state.connections.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, false, 3360862318)
  }, [_vm._v(" Возвраты ")]), _c('div', {
    staticClass: "mt32"
  }, [_c('div', {
    staticClass: "table-filters"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1), _vm.reportSettings ? _c('returns-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "paginatable": true,
      "configurable": true,
      "file-name": _vm.fileName
    }
  }) : _vm._e()], 1)], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };